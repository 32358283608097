export const notUndefined = <T>(value: T | undefined): value is T =>
  value !== undefined;

export const notNull = <T>(value: T | null): value is T => value !== null;

/**
 * Returns true, if the given value is truthy.
 *
 * @see https://developer.mozilla.org/en-US/docs/Glossary/Truthy
 * @see https://developer.mozilla.org/en-US/docs/Glossary/Falsy
 */
export const isTruthy = <T>(
  value: T | undefined | null | false | '' | 0 | -0 | 0n
): value is T => !!value;
